import NavBar from './components/NavBar'
import StockCompare from './components/StockCompare';
import InvestmentSimulator from './components/InvestmentSimulator';
import PacComputer from './components/PacComputer';
import EarningsIpoCalendar from './components/Calendar';
import Footer from './footer';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";


library.add(faTimes, faGlobe);

function App() {
    return (
        <>
            <BrowserRouter>
                <NavBar />
                <Routes>
                    <Route path="/" element={<Navigate replace to="/cmp" />} />
                    <Route path="/cmp" element={<StockCompare />} />
                    <Route path="/cal" element={<EarningsIpoCalendar />} />
                    <Route path="/sim" element={<InvestmentSimulator />} />
                    <Route path="/pac" element={<PacComputer />} />
                </Routes>
            </BrowserRouter>
            <Footer />
        </>
    );
}

export default App;
