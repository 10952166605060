import {
    useReactTable,
    ColumnDef,
    getCoreRowModel,
    flexRender,
    getSortedRowModel,
    SortingState }
    from '@tanstack/react-table';
import yfLogo from '../images/yf.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TableRow } from "../model/StockCompare";
import { useState } from 'react';


const Table = ({data, onremove} :
    { data: TableRow[], onremove: (a: number) => void }) => {

    const [sorting, setSorting] = useState<SortingState>([])

    const columns: ColumnDef<TableRow>[] = [
        {
            header: () => <span title="this is the explanation">Stock</span>,
            accessorKey: 'stock',
            cell: props => {
                return (
                    <>
                        <a href={"https://finance.yahoo.com/quote/" + props.row.original.stock.symbol}>
                            <img src={yfLogo} style={{ width: "15px", height: "15px", marginRight: "10px" }} alt="YF" />
                        </a>
                        <a title={props.row.original.stock.description} href={props.row.original.stock.url}>{props.row.original.stock.name}</a>
                    </>
                );
            }
        },
        {
            header: 'Country',
            accessorKey: 'country',
        },
        {
            header: 'Industry',
            accessorKey: 'industry',
        },
        {
            header: 'Earning P.E. [k]',
            accessorKey: 'earningsPerEmployee',
            cell: props => {
                return (
                    <>
                        {props.getValue<number>() > 0 ? props.renderValue<number>() :
                            <span className="has-text-danger">{props.renderValue<number>()}</span>}
                    </>
                );
            }
        },
        {
            header: 'Vol [M]',
            accessorKey: 'vol',
        },
        {
            header: 'Year Low/High',
            accessorKey: 'yearLowHigh',
        },
        {
            header: 'Quote',
            accessorKey: 'quote',
        },
        {
            header: 'Target',
            accessorKey: 'target',
        },
        {
            header: '% Diff',
            accessorKey: 'diff',
        },
        {
            header: 'Recom',
            accessorKey: 'recomm',
        },
        {
            header: "",
            id: "delete",
            cell: props => {
                return (
                    <span className="icon is-small" onClick={() => onremove(parseInt(props.row.id))}>
                        <FontAwesomeIcon icon="times" />
                    </span>
                )
            }
        }
    ];

    const table = useReactTable({
        columns,
        data,
        state: {
	      sorting,
	    },
	    onSortingChange: setSorting,
	    getCoreRowModel: getCoreRowModel(),
	    getSortedRowModel: getSortedRowModel(),
	    debugTable: true,
        enableMultiSort: true
    });

    return (
        <table className="table is-striped is-hoverable is-fullwidth">
            <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => {
                            return (
                                <th key={header.id} colSpan={header.colSpan}>
                                    <div
                                        {...{
                                            className: header.column.getCanSort()
                                                ? 'cursor-pointer select-none'
                                                : '',
                                            onClick: header.column.getToggleSortingHandler(),
                                        }}
                                    >
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                        {{
                                            asc: ' ↓',
                                            desc: ' ↑',
                                        }[header.column.getIsSorted() as string] ?? null}
                                    </div>
                                </th>
                            )
                        })}
                    </tr>
                ))}
            </thead>
            <tbody>
                {table.getRowModel().rows.map(row => {
                        return (
                            <tr key={row.id}>
                                {row.getVisibleCells().map(cell => {
                                    return (
                                        <td key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
            </tbody>
        </table>
    )
}

export default Table;