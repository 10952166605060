import React from 'react';
import { Link } from 'react-router-dom';

class NavBar extends React.Component {
    render() {
        return (
            <nav className="navbar" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <a className="navbar-item has-text-weight-semibold" href="/">
                        StockRcmdr
                    </a>

                    <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="mainNavbar" href="/">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>

                <div id="mainNavbar" className="navbar-menu">
                    <div className="navbar-start">
                        <Link className="navbar-item" to="/cmp">Compare</Link>
                        <Link className="navbar-item" to="/cal">Calendar</Link>
                        <Link className="navbar-item" to="/sim">Simulator</Link>
                        <Link className="navbar-item" to="/pac">PAC</Link>
                    </div>
                </div>
            </nav>
        )
    }
}

export default NavBar;