import { AutocompleteArgs } from "../model/Autocomplete";

// XXX see also: https://codesandbox.io/s/bulma-autocomplete-forked-xxb6k?file=/src/Autocomplete.jsx
const AutocompleteInput = (args: AutocompleteArgs) => {

    const SuggestionsList = () => {
        return args.suggestions.length ? (
            <div className="dropdown-menu">
                <div className="dropdown-content">
                    <ul className="content is-small">
                        {args.suggestions.map((suggestion, index) => {
                            let className;
                            // Flag the active suggestion with a class
                            if (index === args.activeSuggestionIndex) {
                                className = "has-background-info-light";
                            }
                            return (
                                <li className={className} key={suggestion.symbol} onClick={args.onSuggestionClick}>
                                    {suggestion.description + " - " + suggestion.symbol}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        ) : <div></div>;
    };

    return (
        <div className="dropdown is-active" style={{ display: "inherit"}}>
                <div className="dropdown-trigger">
                    <div className="field">
                        <div className={args.isLoading ? "is-loading" : ""}>
                            <input
                                autoFocus
                                type="text"
                                onChange={args.onInputChange}
                                onKeyDown={args.onKeyDown}
                                value={args.input}
                                placeholder="Name"
                                className="input"
                            />
                        </div>
                    </div>
                </div>
                {args.showSuggestions && args.input && <SuggestionsList />}
        </div>
    );
};

export default AutocompleteInput;