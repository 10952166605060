import { MouseEvent, useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IrishInvestment, ItalianInvestment } from "../investments/InvestmentsByCountry";

const InvestmentSimulator = () => {

    const [initialInvestment, setInitialInvestment] = useState(24000);
    const [annualFees, setAnnualFees] = useState(0.4);
    const [annualInterestRate, setAnnualInterestRate] = useState(7);
    const [years, setYears] = useState(10);
    const [countryA, setCountryA] = useState("IE");
    const [countryB, setCountryB] = useState("IT");
    const [output, setOutput] = useState("");

    const getInvestmentCountry = (code: string) => {
        switch(code) {
            case "IE":
                return new IrishInvestment(initialInvestment, annualInterestRate, annualFees);
            case "IT":
                return new ItalianInvestment(initialInvestment, annualInterestRate, annualFees);
            default:
                throw new Error("Error: country code not supported")
        }
    }

    const handleSimulateClick = (_e: MouseEvent<HTMLButtonElement>) => {
        let out = `Starting simulation\n`;
        const firstCountry = getInvestmentCountry(countryA);
        const secondCountry = getInvestmentCountry(countryB);

        for (let y=0; y < years; y++) {
            firstCountry.yearlyCycle();
            secondCountry.yearlyCycle();
            out += `Year ${y.toString().padStart(2, '0')} - ${firstCountry.id}: ${firstCountry.currentCapital.toFixed(0).padStart(7, ' ')}€ `;
            out += `| ${secondCountry.id}: ${secondCountry.currentCapital.toFixed(0).padStart(7, ' ')}€ `;
            out += `| ratio: ${(firstCountry.currentCapital / secondCountry.currentCapital * 100).toFixed(0).padStart(3, ' ')}%,`;
            out += ` diff: ${(firstCountry.currentCapital - secondCountry.currentCapital).toFixed(0).padStart(6, ' ')}€\n`
        }

        out += `\nSell taxation\t-\t ${firstCountry.id}: ${firstCountry.getCapitalGainTax().toFixed(0)}€ (${firstCountry.taxRate * 100}%) `;
        out += `| ${secondCountry.id}: ${secondCountry.getCapitalGainTax().toFixed(0)}€ (${secondCountry.taxRate * 100}%)\n`;
        out += `Capital gain\t-\t ${firstCountry.id}: ${firstCountry.getCapitalGain().toFixed(0)}€ (${firstCountry.getGainPercent().toFixed(0)}%) `;
        out += `| ${secondCountry.id}: ${secondCountry.getCapitalGain().toFixed(0)}€ (${secondCountry.getGainPercent().toFixed(0)}%) `;

        setOutput(out);
    }

    const countries_dropdown_list = <>
        <option value="IE">Ireland (CGT for ETFs: 41%; deemed disposal)</option>
        <option value="IT">Italy (CGT: 26%)</option>
    </>;

    return (
        <section className="section is-medium">
            <div className="columns is-mobile">
                <div className="column is-one-quarter is-offset-one-quarter">
                    <div className="field">
                        <div className="control">
                            <label className="label">
                            Initial investment (€) <input className="input" type="number" placeholder="initial investment" value={initialInvestment}
                                    onChange={(e) => { !isNaN(parseInt(e.target.value)) && setInitialInvestment(parseInt(e.target.value)) }} />
                            </label>
                        </div>
                    </div>
                    <div className="field">
                        <div className="control">
                            <label className="label">
                            Annual interest rate (%) <input className="input" type="number" placeholder="annual interest rate" value={annualInterestRate}
                                onChange={(e) => { !isNaN(parseFloat(e.target.value)) && setAnnualInterestRate(parseFloat(e.target.value)) }} />
                            </label>
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">Country A <p className="control has-icons-left is-expanded">
                            <span className="select is-fullwidth">
                                <select value={countryA} onChange={(e) => {setCountryA(e.target.value)}}>
                                    {countries_dropdown_list}
                                </select>
                            </span>
                            <span className="icon is-small is-left">
                                <FontAwesomeIcon icon="globe" />
                            </span>
                        </p>
                        </label>
                    </div>
                </div>

                <div className="column is-one-quarter">
                    <div className="field">
                        <div className="control">
                            <label className="label">Years <input className="input" type="number" placeholder="years to simulate" value={years}
                                onChange={(e) => { !isNaN(parseInt(e.target.value)) && setYears(parseInt(e.target.value)) }} />
                            </label>
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">Annual fees (%) <div className="control">
                            <input className="input" type="number" placeholder="investment fee (%)" value={annualFees}
                                onChange={(e) => { setAnnualFees(parseFloat(e.target.value)) }} />
                        </div>
                        </label>
                    </div>
                    <div className="field">
                        <label className="label">Country B<p className="control has-icons-left is-expanded">
                            <span className="select is-fullwidth">
                                <select value={countryB} onChange={(e) => {setCountryB(e.target.value)}}>
                                    {countries_dropdown_list}
                                </select>
                            </span>
                            <span className="icon is-small is-left">
                                <FontAwesomeIcon icon="globe" />
                            </span>
                        </p>
                        </label>
                    </div>
                </div>
            </div>
            <div className="columns is-mobile">
                <div className="column is-one-fifth is-offset-two-fifths">
                    <div className="control is-expanded">
                        <button className="button is-primary is-fullwidth" onClick={handleSimulateClick}>Simulate</button>
                    </div>
                </div>
            </div>
            <div className="columns is-mobile">
                <div className="column is-half is-offset-one-quarter">
                    {output &&
                        <textarea className="textarea is-family-monospace" placeholder="output" rows={15} value={output} readOnly></textarea>}
                </div>
            </div>
        </section>
    )
}

export default InvestmentSimulator;