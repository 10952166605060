const FINNHUB_BASEURL = "https://finnhub.io/api/v1/"
const FINNHUB_TOKEN = "token=c0p7kp748v6rvej4etcg"
const finnhubProfileUrl = `${FINNHUB_BASEURL}stock/profile2?${FINNHUB_TOKEN}&symbol=`
const finnhubRecommendationUrl = `${FINNHUB_BASEURL}stock/recommendation?${FINNHUB_TOKEN}&symbol=`
const finnhubMetricsUrl = `${FINNHUB_BASEURL}stock/metric?${FINNHUB_TOKEN}&metric=all&symbol=`
const finnhubQuoteUrl = `${FINNHUB_BASEURL}quote?${FINNHUB_TOKEN}&symbol=`
const finnhubCalIpoUrl = `${FINNHUB_BASEURL}calendar/ipo?${FINNHUB_TOKEN}&` // from, to
const finnhubCalEarningsUrl = `${FINNHUB_BASEURL}calendar/earnings?${FINNHUB_TOKEN}&` // from, to
//const finnhubSearchUrl = `${FINNHUB_BASEURL}search?${FINNHUB_TOKEN}&q=`

export {
    finnhubProfileUrl, finnhubRecommendationUrl, finnhubMetricsUrl,
    finnhubQuoteUrl, finnhubCalIpoUrl, finnhubCalEarningsUrl
}