import React from 'react';

const footerStyle: React.CSSProperties = {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    padding: '2rem 1.5rem 2rem',
    color: 'lightgrey',
};

const Footer = () => {
    const buildDate = new Date(process.env.REACT_APP_BUILD_TIME ?? "");

    return (
        <footer style={footerStyle}>
            <div className="content has-text-centered is-size-7">
                This site was built on: {buildDate.toISOString()}
            </div>
        </footer>
    );
};

export default Footer;