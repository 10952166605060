import React, { useEffect, useState } from "react";

import AutocompleteInput from './AutocompleteInput';
import Table from './Table';
import {
    finnhubMetricsUrl, finnhubProfileUrl,
    finnhubQuoteUrl, finnhubRecommendationUrl
} from '../clients/Finnhub'
import { useCancelableFetch, yahooQuoteSummaryRequest } from "../clients/Yahoo";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StockCompareResponse, Recommendation, TableRow } from "../model/StockCompare";


const StockCompare = () => {

    const [input, setInput] = useState("");
    const [symbol, setSymbol] = useState("");
    const [data, setData] = useState([] as TableRow[])
    const [symbols, setSymbols] = useState([] as string[]);
    const [activeSuggestionIdx, setActiveSuggestionIndex] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const { data: searchResults, isLoading } = useCancelableFetch(input);
    const [isFetching, setIsFetching] = useState(false);

    const MAX_SEARCH_RESULTS = 5;

    // Executed only once upon initial rendering
    useEffect(() => {
        const storedSymbols = localStorage.getItem('symbols');
        console.log(`Loaded from local storage: '${storedSymbols}'`);
        if (storedSymbols !== null && storedSymbols !== undefined && storedSymbols !== '[]') {
            const symbolsArray = JSON.parse(storedSymbols);
            console.log(`symbols was NOT null: ${symbolsArray}`);
            Promise.all(symbolsArray.map((stockSymbol: string) => {
                console.log(`Fetching ${stockSymbol}`);
                return fetchSymbolData(stockSymbol);
            })).then((newRows) => {
                setData(newRows);
                setSymbols(symbolsArray);
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Sets list of symbols in localStorage every time it changes
    useEffect(() => {
        localStorage.setItem('symbols', JSON.stringify(symbols));
    }, [symbols]);

    const handleNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const nameInputTextValue = e.target.value;
        if (nameInputTextValue !== "") {
            setShowSuggestions(true);
        } else {
            setSymbol("");
            setShowSuggestions(false);
            setActiveSuggestionIndex(0);
        }
        setInput(nameInputTextValue);
    }

    const handleSymbolInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const symbolInputTextValue = e.target.value;
        setSymbol(symbolInputTextValue);
    }

    const handleSuggestionClick = (e: React.MouseEvent<HTMLLIElement>) => {
        const parts = (e.target as HTMLLIElement).innerText.split("-");
        const inputSymbol = parts?.at(-1)?.trim().toUpperCase() ?? "ERROR";
        const desc = parts.slice(0, -1).join("").trim();
        setInput(desc);
        setSymbol(inputSymbol);
        setActiveSuggestionIndex(0);
        setShowSuggestions(false);
    }

    const handleInputKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            if (showSuggestions) {
                setInput(searchResults[activeSuggestionIdx].description);
                setSymbol(searchResults[activeSuggestionIdx].symbol);
                setActiveSuggestionIndex(0);
                setShowSuggestions(false);
            }
        } else if (e.key === "ArrowUp") {
            if (activeSuggestionIdx === 0) {
                return;
            }
            setActiveSuggestionIndex(activeSuggestionIdx - 1);
        } else if (e.key === "ArrowDown") {
            if (activeSuggestionIdx === Math.min(MAX_SEARCH_RESULTS - 1, searchResults.length + 1)) {
                return;
            }
            setActiveSuggestionIndex(activeSuggestionIdx + 1);
        }
    }

    const handleClearClick = (_e: React.MouseEvent) => {
        setData([]);
        setSymbols([]);
    }

    const handleAddClick = (_e: React.MouseEvent) => {
        const inputSymbol = symbol.toUpperCase();
        fetchSymbolData(inputSymbol).then((newRow) => {
            setSymbols([...symbols, newRow.stock.symbol]);
            setData([...data, newRow]);
        })
    }

    function fetchSymbolData(fetchSymbol: string) {
        console.log(`Fetching: ${fetchSymbol}`);
        setIsFetching(true);

        return Promise.all([
            fetch(finnhubProfileUrl + fetchSymbol),
            fetch(finnhubRecommendationUrl + fetchSymbol),
            fetch(finnhubQuoteUrl + fetchSymbol),
            fetch(finnhubMetricsUrl + fetchSymbol),
            fetch(yahooQuoteSummaryRequest(fetchSymbol))
        ]).then(function (responses) {
            return Promise.all(responses.map(function (response) {
                return response.json();
            }));
        }).then(function (respData) {
            return handleNewStockData(respData as StockCompareResponse);
        }).catch(function (error) {
            console.log(error);
            throw error;
        }).finally(() => {
            setIsFetching(false);
        });
    }

    const handleOnRemoveRow = (rowIndex: number) => {
        const dataCopy = [...data];
        dataCopy.splice(rowIndex, 1);
        setData(dataCopy);

        const symbolToRemove = data[rowIndex].stock.symbol;
        const newSymbols = symbols.filter(item => item !== symbolToRemove);
        setSymbols(newSymbols);
    }

    const computeRecommendationScore = (rec: Recommendation[]) => {
        if (!Array.isArray(rec) || !rec.length)
            return [null, null];
        const sum = [rec[0].strongBuy, rec[0].buy, rec[0].hold, rec[0].sell, rec[0].strongSell]
            .reduce((a, b) => a + b, 0);
        const weightedSum = rec[0].strongBuy * 1 + rec[0].buy * 2 + rec[0].hold * 3 +
            rec[0].sell * 4 + rec[0].strongSell * 5;
        return [Number((weightedSum / sum).toFixed(1)), sum];
    }

    function handleNewStockData(dataArray: StockCompareResponse) {
        // console.log(dataArray);
        const [profile, rec, quote, metrics, yahoo] = dataArray;

        const stock = {
            name: profile.name,
            url: profile.weburl,
            description: yahoo.longBusinessSummary,
            symbol: profile.ticker
        }
        const country = profile.country;
        const industry = profile.finnhubIndustry;
        const yearLowHigh = `[${metrics.metric?.["52WeekLow"]?.toFixed(0) ?? "N/A"}, ${metrics.metric?.["52WeekHigh"]?.toFixed(0) ?? "N/A"}]`;
        const vol = metrics.metric["10DayAverageTradingVolume"]?.toFixed(1) ?? "N/A";
        const quotePrice = quote.c;
        const meanTargetPrice = yahoo.targetMeanPrice ?? "N/A";
        const target = meanTargetPrice + " (" + (yahoo.numberOfAnalystOpinions ?? "N/A") + ")";
        const diff = (((quotePrice - meanTargetPrice) / quotePrice) * 100).toFixed(1) ?? "N/A";
        const yahooRecScore = yahoo.recommendationMean ?? "N/A";
        const [recScore, numRec] = computeRecommendationScore(rec);
        console.assert(yahooRecScore === recScore,
            `Yahoo recommendation score != Finnhub recommendation score: ${yahooRecScore} != ${recScore}`);
        const recomm = `${recScore} (${numRec})`;
        const earningsPerEmployee = (metrics?.metric["netIncomeEmployeeAnnual"] * 1000).toFixed(0) ?? "N/A";

        return {
            stock: stock,
            country: country,
            industry: industry,
            earningsPerEmployee: earningsPerEmployee,
            yearLowHigh: yearLowHigh,
            vol: vol,
            quote: quotePrice,
            target: target,
            diff: diff,
            recomm: recomm,
        };
    }

    return (
        <section className="section is-medium">
            <div className="columns is-centered">
                <div className="column is-two-fifths">
                    <div className="columns is-centered">
                        <div className="column is-three-fifths">
                            <AutocompleteInput
                                input={input}
                                suggestions={searchResults.slice(0, MAX_SEARCH_RESULTS)}
                                activeSuggestionIndex={activeSuggestionIdx}
                                showSuggestions={showSuggestions && input.length > 2}
                                onInputChange={handleNameInputChange}
                                onKeyDown={handleInputKeyDown}
                                onSuggestionClick={handleSuggestionClick}
                                isLoading={isLoading} />
                        </div>
                        <div className="column is-two-fifths">
                            <div className="field has-addons has-addons-centered">
                                <div className={"control " + (isLoading || isFetching ? "is-loading" : "")}>
                                    <input
                                        type="text"
                                        value={symbol}
                                        placeholder="Symbol"
                                        className="input"
                                        onInput={handleSymbolInputChange}
                                    />
                                </div>
                                <div className="control">
                                    <button className="button is-primary" onClick={handleAddClick}>Add</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {data.length > 0 && <>
                <div className="columns is-centered">
                    <div className="column is-four-fifths">
                        <Table data={data} onremove={handleOnRemoveRow} />
                    </div>
                </div >
                <div className="columns is-centered">
                    <div className="column">
                        <div className="field has-addons has-addons-centered">
                            <div className="control">
                                <button className="button is-light" onClick={handleClearClick}>
                                    <span>Clear</span>
                                    <span className="icon is-small">
                                        <FontAwesomeIcon icon="times" />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div >
            </>
            }
        </section >

    )
}

export default StockCompare;