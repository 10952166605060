import { Calendar, dateFnsLocalizer, EventPropGetter, NavigateAction, View } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import ie from 'date-fns/locale/en-IE'
import { useCallback, useEffect, useState } from 'react'
import { finnhubCalEarningsUrl, finnhubCalIpoUrl } from '../clients/Finnhub'
import { endOfMonth, formatISO, startOfMonth, min, max, endOfWeek } from 'date-fns'
import { CalendarEvent, EarningCalendarResponse, IpoCalendarResponse } from '../model/Calendar'

const locales = {
    'en-IE': ie,
}

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})

const d = (date: Date) => {
    return formatISO(date, { representation: 'date' });
}

function Event({ event }: { event: CalendarEvent }) {
    return (
        <div>
            <a href={'https://finance.yahoo.com/quote/' + event.symbol}
                target="_blank"
                rel="noreferrer"
                style={{ color: "white" }}>
                {event.title}
            </a>
        </div>
    )
}

const customEventPropGetter : EventPropGetter<CalendarEvent> =
    (event: CalendarEvent, _start: Date, _end: Date, _isSelected: boolean) => {
    if (event.type === "IPO") {
        return {
            style: {
                backgroundColor: "green"
            }
        }
    } else {
        return {}
    }
}


const EarningsIpoCalendar = () => {

    const [events, setEvents] = useState([] as CalendarEvent[]);
    const [startCoverage, setStartCoverage] = useState(new Date());
    const [endCoverage, setEndCoverage] = useState(new Date());

    const onNavigate = (newDate: Date, view: View, action: NavigateAction) => {
        console.log(`onNavigate: ${newDate} - ${view} - ${action}`)
        const startPeriod = view === "month" ? startOfMonth(newDate) : startOfWeek(newDate);
        const endPeriod = view === "month" ? endOfMonth(newDate) : endOfWeek(newDate);
        if (startPeriod < startCoverage) {
            memoizedLoadEvents(startPeriod, startCoverage);
        } else if (endPeriod > endCoverage) {
            memoizedLoadEvents(endCoverage, endPeriod);
        }
    }

    const memoizedLoadEvents = useCallback((startDate: Date, endDate: Date) => {
        console.log(`loading events ${startDate} - ${endDate}`)
        Promise.all([
            fetch(finnhubCalIpoUrl + `from=${d(startDate)}&to=${d(endDate)}`),
            fetch(finnhubCalEarningsUrl + `from=${d(startDate)}&to=${d(endDate)}`),
        ]).then(function (responses) {
            return Promise.all(
                responses.map(function (response) {
                    return response.json();
                }));
        }).then((dataArray) => {
            console.log(dataArray);
            const [ipos, earnings] = dataArray as [IpoCalendarResponse, EarningCalendarResponse];
            const ipoEvents = ipos.ipoCalendar.filter(
                event =>
                    event.status === "expected")
                .map(event => {
                    return {
                        start: new Date(Date.parse(event.date)),
                        end: new Date(Date.parse(event.date)),
                        title: event.name,
                        type: "IPO",
                        symbol: event.symbol
                    }
                })
            const earningsEvents = earnings.earningsCalendar.filter(
                event =>
                    !!event.epsEstimate && event.epsEstimate > 0.2 &&
                    event.epsActual === null)
                .map(event => {
                    return {
                        start: new Date(Date.parse(event.date)),
                        end: new Date(Date.parse(event.date)),
                        title: event.symbol,
                        type: "earning",
                        symbol: event.symbol
                    }
                })
            const newEvents = [...ipoEvents, ...earningsEvents]
            setEvents([...events, ...newEvents as CalendarEvent[]]);
            setStartCoverage(min([startDate, startCoverage]));
            setEndCoverage(max([endDate, endCoverage]));
        }).catch(respError => {
            console.error(`Error: ${respError}`);
        });
    }, [startCoverage, endCoverage, events])

    useEffect(() => {
        const now = new Date();
        memoizedLoadEvents(startOfMonth(now), endOfMonth(now));
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <section className="section is-medium">
            <div className="columns is-centered">
                <div className="column is-half">
                    <div>Here are <span style={{ backgroundColor: "green", color: "white" }}>expected IPOs
                    </span> and <span style={{ backgroundColor: "#3174ad", color: "white" }}>earnings</span> (with
                        EPS estimate &gt; 0.2).</div>
                </div>
            </div>
            <div className="columns is-centered">
                <div className="column is-half">
                    <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 500 }}
                        drilldownView={null}
                        views={['month', 'week']}
                        culture="en-IE"
                        popup
                        components={{ event: Event }}
                        eventPropGetter={customEventPropGetter}
                        onNavigate={onNavigate}
                    />
                </div>
            </div>
        </section>
    )
}

export default EarningsIpoCalendar;