abstract class InvestmentByCountry {
    id: string = "";
    currentYear: number = 0;
    taxRate: number = 0.2;
    initialCapital: number;
    currentCapital: number;
    interestRate: number;
    fees: number;

    constructor(initalCapital: number, interestRate: number, fees: number) {
        this.initialCapital = initalCapital;
        this.currentCapital = initalCapital;
        this.interestRate = interestRate / 100;
        this.fees = fees / 100;
        this.taxRate = 0;
    }

    applyInterest() {
        this.currentCapital += this.currentCapital * this.interestRate;
    }

    applyFees() {
        this.currentCapital -= this.currentCapital * this.fees;
    }

    abstract applyCountrySpecificTaxes() : void;

    getCapitalGain() {
        return this.currentCapital - this.initialCapital;
    }

    getCapitalGainTax() {
        return this.getCapitalGain() * this.taxRate;
    }

    getCapitalAfterTaxes() {
        let capitalGainTax = this.getCapitalGain() * this.taxRate;
        return this.currentCapital - capitalGainTax;
    }

    getGainPercent() {
        return ((this.currentCapital - this.initialCapital) / this.initialCapital ) * 100;
    }

    getGain() {
        return this.currentCapital - this.initialCapital;
    }

    incrementYear() {
        this.currentYear += 1;
    }

    yearlyCycle() {
        this.applyCountrySpecificTaxes();
        this.applyFees();
        this.applyInterest();
        this.incrementYear();
    }
}

class ItalianInvestment extends InvestmentByCountry {

    constructor(initalCapital: number, interestRate: number, fees: number) {
        super(initalCapital, interestRate, fees);
        this.taxRate = 0.26;
        this.id = "IT";
    }

    applyCountrySpecificTaxes(): void {
        /* No country-specific taxes in Italy */
    }
}

class IrishInvestment extends InvestmentByCountry {

    constructor(initalCapital: number, interestRate: number, fees: number) {
        super(initalCapital, interestRate, fees);
        this.taxRate = 0.41;
        this.id = "IE";
    }

    applyCountrySpecificTaxes(): void {
        if (this.currentYear % 9 === 0) {
            this.currentCapital -= this.getCapitalGain() * this.taxRate;
        }
    }
}

export {IrishInvestment, ItalianInvestment};