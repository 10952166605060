
import { PacPortfolioItem } from '../model/Pac';
import { useState, useEffect} from 'react';
import yfLogo from '../images/yf.png';
import { yahooQuoteSummaryRequest } from '../clients/Yahoo';
import { QuoteSummary } from '../model/StockCompare';


const defaultData: PacPortfolioItem[] = [
    {
        ticker: 'EMIM.AS', name: "iShares Core MSCI EM IMI UCITS",
        price: 0, position: 0, allocation: 10
    },
    {
        ticker: 'IWDA.AS', name: "iShares Core MSCI World UCITS",
        price: 0, position: 0, allocation: 30
    },
    {
        ticker: 'SXR8.DE', name: "iShares Core S&P 500 UCITS",
        price: 0, position: 0, allocation: 30
    },
    {
        ticker: 'MEUD.PA', name: "Lyxor Core STOXX Europe 600",
        price: 0, position: 0, allocation: 30
    },
];

const PacComputer = () => {

    const [data, setData] = useState<PacPortfolioItem[]>(defaultData)
    const [output, setOutput] = useState("");
    const [investment, setInvestment] = useState(1000);

    // Executed only once upon initial rendering
    useEffect(() => {
        async function fetchQuote(ticker: string): Promise<number> {
            const resp = await fetch(yahooQuoteSummaryRequest(ticker));
            return Promise.resolve(resp.json().then((data: QuoteSummary) => {
                // console.log(`Fetched ${ticker} price: ${data.price}`);
                return data.price;
            }));
        }

        const localData = localStorage.getItem('pac-portfolio');
        let newData = localData ? JSON.parse(localData) as PacPortfolioItem[] : defaultData;

        let promises = []
        for (let i = 0; i < newData.length; i++) {
            promises.push(fetchQuote(newData[i].ticker));
        }
        Promise.all(promises).then((resp) => {
            // console.log(`Got all prices: ${resp}`);
            for (let i = 0; i < newData.length; i++) {
                newData[i].price = resp[i];
            }
            // console.log(`Setting data after fetching it from API: ${JSON.stringify(newData)}`);
            setData([...newData]);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function computePacContributions(): void {
        const totalPortfolio = data.reduce((acc, item) => acc + item.position * item.price, 0);
        const newTotalPortfolio = totalPortfolio + investment;
        const targetValues = data.map(item => Math.max(0, newTotalPortfolio * item.allocation / 100));
        const targetPositions = targetValues.map((targetValue, index) => Math.max(0, Math.floor(targetValue / data[index].price)));
        const contributions = targetPositions.map((targetPosition, index) => Math.max(0, Math.floor(targetPosition - data[index].position)));
        const newAllocations = targetPositions.map((targetPosition, index) => ((targetPosition * data[index].price) / newTotalPortfolio * 100).toFixed(1));
        let out = `Total portfolio: ${totalPortfolio.toFixed(2)}€\n` +
            `New total portfolio: ${newTotalPortfolio.toFixed(2)}€\n\n`;

        const outputArray = data.map((item, index) => {
            return `Buy ${Math.floor(contributions[index])} units of ${item.name} at ${item.price.toFixed(2)}€ = ${(contributions[index] * item.price).toFixed(2)} ==> ${newAllocations[index]}%`
        })
        out += outputArray.join('\n');
        setOutput(out);
    }

    function handlePositionInputChange(newValue: number, index: number): void {
        data[index].position = newValue;
        setData([...data]);
        localStorage.setItem('pac-portfolio', JSON.stringify(data));
    }

    function handleAllocationInputChange(newValue: number, index: number): void {
        data[index].allocation = newValue;
        setData([...data]);
        localStorage.setItem('pac-portfolio', JSON.stringify(data));
    }

    return (
        <section className="section is-medium">
            <div className="columns is-centered">
                <div className="column is-four-fifths">
                    <table className="table is-striped is-hoverable is-fullwidth">
                        <thead>
                            <tr>
                                <th>ETF</th>
                                <th>Price [€]</th>
                                <th>Position</th>
                                <th>Allocation (%)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => {
                                return <tr key={index}>
                                    <td>
                                        <a href={"https://finance.yahoo.com/quote/" + item.ticker}>
                                            <img src={yfLogo} style={{ width: "15px", height: "15px", marginRight: "10px" }} alt="YF" />
                                        </a>
                                        {item.name}</td>
                                    <td>{item.price.toFixed(2)}</td>
                                    <td><input type="number" value={item.position} key="position" className="input"
                                        onChange={(e) => !isNaN(parseInt(e.target.value)) && handlePositionInputChange(parseInt(e.target.value), index)} /></td>
                                    <td><input type="number" value={item.allocation} key="allocation" className='input'
                                        onChange={(e) => !isNaN(parseInt(e.target.value)) && handleAllocationInputChange(parseInt(e.target.value), index)} /></td>
                                </tr>
                            })
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="columns is-centered">
                <div className="column is-one-fifth">
                    <div className="field">
                        <div className="control">
                            <label className="label">
                                Investment [€]: <input className="input" type="number"
                                    onChange={(e) => !isNaN(parseInt(e.target.value)) && setInvestment(parseInt(e.target.value))}
                                    value={investment} />
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="columns is-centered">
                <div className="column is-one-fifth is-centered">
                    <div className="field has-addons has-addons-centered">
                        <div className="control">
                            <button className="button is-primary" onClick={_ => computePacContributions()}>Compute contributions</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="columns is-centered">
                <div className="column is-two-fifths">
                    <textarea className="textarea is-family-monospace" placeholder="output" rows={8} value={output} readOnly></textarea>
                </div>
            </div>
        </section>
    )
}

export default PacComputer;