import { useEffect, useState } from "react"
import { AutocompleteData, SearchResponse } from "../model/Yahoo"


const YAHOO_SERVER_URL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? "http://127.0.0.1:5000" : "https://yf.vshed.xyz"

const yahooQuoteSummaryRequest = (symbol: string) => {
    return new Request(`${YAHOO_SERVER_URL}/stock/${symbol}`)
}

const yahooSearchRequest = (query: string) => {
    return new Request(`${YAHOO_SERVER_URL}/search?q=${query}`)
}

// From: https://github.com/dogriffiths/ReactCookbook-source/tree/master/ch05-03-cancel
const useCancelableFetch = (terms: string) => {
    const [data, setData] = useState([] as AutocompleteData[])
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState(undefined as Error | undefined)

    useEffect(() => {
        setError(undefined);
        if (terms) {
            setLoading(true);
            const controller = new AbortController()
            const abortSignal = controller.signal
            fetch(yahooSearchRequest(terms), {
                signal: abortSignal
            }).then(response => response.json())
                .then((respJson : SearchResponse) => {
                    // console.log(respJson);
                    setData(respJson.quotes.map(x => (
                        {
                            symbol: x.symbol,
                            description: x.shortname
                        }
                    )));
                }).catch(respError => {
                    //console.error(`Error: ${respError}`);
                    setError(respError);
                }).finally(() =>
                    setLoading(false)
                );

            return () => {
                controller.abort();
            }
        } else {
            setData([])
            setLoading(false)
        }
    }, [terms])

    return { data, isLoading, error }
}

export { yahooQuoteSummaryRequest, useCancelableFetch };